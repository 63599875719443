import '../scss/app.scss'; // Vite requires css import in app.js

import { $ } from './helpers/query-selector';
import initLazyLoad from './modules/lazyload';
import initSetVh from './modules/set-vh';
import initNav from './modules/nav';
import initLang from './modules/lang';

function init() {
  initLazyLoad();
  initSetVh();
  initNav();
  initLang();

  if ($('.js-login')) import('./modules/login').then(m => m.default());

  if ($('.js-iframe')) import('./modules/iframe').then(m => m.default());

  if ($('.js-lottie')) import('./modules/lottie').then(m => m.default());

  if ($('.js-textarea')) import('./modules/textarea').then(m => m.default());
}

init();
